import React, { useCallback, useState, useContext, useRef } from 'react';
import { Layout, Image, Button, Flex } from 'antd';
import { AuthUserContext } from '../Contexts';
import { FirebaseContext } from '../Firebase';
import { Capacitor } from '@capacitor/core';

import { signOut } from "firebase/auth";

import { useNavigate, useLocation } from 'react-router-dom';
import * as ROUTES from "../../constants/routes";

import { siteName } from '../../constants';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import PwForget from '../PasswordForget';


import Banner from './banner'; // Ensure this points to the new Banner0 component
import Content0 from './features'; // Ensure this points to the new Content0 component
import { Banner01DataSource, Content00DataSource, SimDataSource, availabilityDataSource } from './data'; // Ensure correct data import
import ChatSimulation from './sim';
import Availability from './availability';
import SiteFooter from './footer';

import { Haptics, ImpactStyle } from '@capacitor/haptics';

const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
};

const { Content, Footer, Header } = Layout;

const isNative = Capacitor.isNativePlatform();

const headerHeight = isNative ? 120 : 80;
const headerPadding = isNative ? "40px 20px" : "0 20px";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const App = () => {

    const firebase = useContext(FirebaseContext);

    const { authUser, isLoading } = useContext(AuthUserContext);
    const navigate = useNavigate(); // Use useNavigate hook for navigation
    const query = useQuery();

    const [signInVisible, setSignInVisible] = useState(false);
    const [signUpVisible, setSignUpVisible] = useState(false);
    const [pwForgetVisible, setPwForgetVisible] = useState(false);

    const chatListRef = useRef(null);

    const handleSignInFinish = useCallback(() => {

        setSignInVisible(false);

        if (authUser) {
            navigate(ROUTES.HOME);
        }

    }, [navigate, authUser]);

    const handleCancel = (type) => {
        if (type === "signup") {
            setSignUpVisible(false);
        } else if (type === "signin") {
            setSignInVisible(false);
        } else if (type === "pwforget") {
            setPwForgetVisible(false);
        }
    }

    // useEffect(() => {
    //     // Read the 'mode' query parameter
    //     const mode = query.get('mode');

    //     // console.log('mode is', mode);

    //     if (mode === 'signup' && !authUser) {
    //         //authUser isn't populated fast enough
    //         setSignUpVisible(true);
    //     } else if (mode === 'signin' && !authUser) {
    //         setSignInVisible(true);
    //     }
    // }, [query]);

    // handle finish of sign up
    const handleFinish = useCallback(() => {
    }, []);

    const goToOtherModal = useCallback((type) => {

        if (type === "signup") {
            handleCancel('signin');
            setSignUpVisible(true);
        }
        else if (type === "signin") {
            handleCancel('signup');
            setSignInVisible(true);
        } else if (type === "pwforget") {
            handleCancel('signin');
            setPwForgetVisible(true);
        }

    }, []);

    const onSignIn = useCallback(() => {

        hapticsImpactLight();

        if (authUser) {
            navigate(ROUTES.HOME);
        } else {
            setSignInVisible(true);
        }

    }, [authUser, navigate]);



    const onSignUp = useCallback(() => {

        if (authUser) {
            navigate(ROUTES.HOME);
        } else {
            setSignUpVisible(true);
        }

    }, [authUser, navigate]);


    // const scrollToBottomOfChat = () => {
    //     const scrollElement = chatListRef.current;

    //     if (scrollElement) {
    //         // console.log('scrollElement is', scrollElement);
    //         // Get the bounding rectangle of the chat element relative to the viewport
    //         const boundingRect = scrollElement.getBoundingClientRect();

    //         // Calculate the position to scroll to: current scroll position + distance from the top of the viewport to the bottom of the chat element
    //         const positionToScrollTo = window.scrollY + boundingRect.bottom;
    //         // const positionToScrollTo = window.scrollY + 20;  // scroll just a little

    //         // Scroll the main page to the desired position
    //         window.scrollTo({
    //             top: positionToScrollTo,
    //             behavior: 'smooth' // Optional: for smooth scrolling
    //         });
    //     }
    // };


    const scrollToBottomOfChat = () => {
        // remove scroll. I don't like the effect
    };

    // Call this function whenever you update the chat and need to scroll


    const onSignOut = useCallback(() => {
        localStorage.setItem('pendingMessages', null);
        signOut(firebase.auth);
        hapticsImpactLight();
    }, [firebase.auth]);

    return (

        <Layout style={{ minHeight: "100vh" }}>
            <Header style={{
                height: headerHeight,
                padding: headerPadding,
            }}>

                <Flex justify='space-around' gap="large" align='end'>
                    <Image
                        height={60}
                        src="/images/logo_text.svg"
                        alt="Tellti.me"
                        preview={false}
                        fallback="Login Required"
                        style={{ display: "flex", justifyContent: 'center', marginTop: "10px", minWidth: "60px" }}
                    // onClick={hapticsImpactLight}
                    />

                    <Button type="primary"
                        onClick={onSignIn}>
                        {authUser ? "To Homepage" : "Sign In"}
                    </Button>

                    {authUser && <Button
                        onClick={onSignOut}
                    >
                        Sign Out
                    </Button>}


                </Flex>


            </Header>

            <Content>
                <SignIn
                    visible={signInVisible}
                    handleCancel={() => handleCancel('signin')}
                    goToOtherModal={goToOtherModal}
                    handleFinish={handleSignInFinish}
                />
                <SignUp visible={signUpVisible}
                    handleCancel={() => handleCancel('signup')}
                    goToOtherModal={() => goToOtherModal('signin')}
                    handleFinish={handleFinish}
                />
                <PwForget visible={pwForgetVisible}
                    handleCancel={() => handleCancel('pwforget')}
                />

                <Banner
                    id="Banner0_1"
                    key="Banner0_1"
                    dataSource={Banner01DataSource}
                    isMobile={isNative}
                    onSignUp={onSignUp}
                    signedIn={authUser}
                />



                {isNative ? null :
                    <Content0 dataSource={Content00DataSource} />}



                {isNative ? null :
                    <div ref={chatListRef} >
                        <ChatSimulation
                            dataSource={SimDataSource}
                            scrollToBottom={scrollToBottomOfChat} />
                    </div>
                }

                {isNative ? null :
                    <Availability isMobile={false} dataSource={availabilityDataSource} />}



                <SiteFooter key="footer" />
            </Content>

            <Footer style={{ textAlign: 'center', padding: '14px', fontSize: "14px", background: '#00152a', position: 'sticky' }}>©{new Date().getFullYear()} {siteName} created by Spearmint Labs</Footer>
        </Layout>

    );
};


export default App;

